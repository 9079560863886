<template>
  <p id="title" class="text-4xl md:text-6xl text-center text-gray" />
</template>

<script>
export default {
  props: {
    title: String,
  },
  mounted() {
    this.showTitle('title', this.title, 0, 30);
  },
  methods: {
    showTitle(target, message, index, interval) {
      const el = document.getElementById('title');

      if (index < message.length) {
        el.append(message[index++]);
        setTimeout(() => this.showTitle(target, message, index, interval), interval);
      }
    },
  },
};
</script>
