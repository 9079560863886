<template>
  <section class="bg-primary">
    <div class="h-96 flex justify-center items-center">
      <AnimateText title="CONTACT US" />
    </div>
  </section>

  <section>
    <div class="md:container mx-auto px-6 py-12">
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="mr-6">
          <div class="mb-8">
            <h1 class="text-2xl text-primary mb-4">Our Address</h1>
            <div class="pl-4">
              <h4>Head Office & Fabrication Laboratory</h4>
              <p>Sentra Industri Terpadu 1 &#38; 2, Blok i1 no. 7</p>
              <p>Elang Laut PIK, Jakarta, Indonesia</p>
              <p>Jakarta Utara 14470</p>
              <h4 class="mt-4">Tanggerang Factory</h4>
              <p>Jl. Raya Gerudug, Mekar Jaya</p>
              <p>Kec. Sepatan, Kabupaten Tangerang, Indonesia</p>
              <p>Banten 15520</p>
            </div>
          </div>
          <div class="mb-12">
            <h1 class="text-2xl text-primary mb-4">Reach us</h1>
            <ul class="pl-4">
              <li class="mb-2">
                <a href="https://www.instagram.com/sginterior.id/" target="_blank">
                  <fa class="text-xl align-middle" :icon="['fab', 'instagram']" />
                  <span class="ml-4">sginterior.id</span>
                </a>
              </li>
              <li class="mb-2">
                <a href="https://wa.me/6289634539713" target="_blank">
                  <fa class="text-xl align-middle" :icon="['fab', 'whatsapp']" />
                  <!-- <span class="ml-4">+6281398939709</span> -->
                  <span class="ml-4">+62 896-3453-9713</span>
                </a>
              </li>
              <li class="mb-2">
                <a href="mailto:lidyagriseldamarcos@gmail.com" target="_blank">
                  <fa class="text-xl align-middle" :icon="['far', 'envelope']" />
                  <span class="ml-4">sginterior.id@gmail.com</span>
                  <!-- <span class="ml-4">lidyagriseldamarcos@gmail.com</span> -->
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Embed
          aspect="16/9"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15867.849848794665!2d106.74606070214723!3d-6.1357460602051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a1d1a36d4d82b%3A0x43a8765edd0efd42!2sSG%20Interior!5e0!3m2!1sen!2sid!4v1636464719523!5m2!1sen!2sid"
        />
        <!-- <Embed aspect="16/9" src="https://maps.google.com/maps?q=uv%20vortex&t=&z=13&ie=UTF8&iwloc=&output=embed" /> -->
      </div>
    </div>
  </section>
</template>

<script>
import { useHead } from '@vueuse/head';
import AnimateText from '@/components/Animation/AnimateText.vue';
import Embed from '@/components/Embed.vue';

export default {
  components: {
    AnimateText,
    Embed,
  },
  setup() {
    useHead({
      title: `Contact Us | ${process.env.VUE_APP_NAME}`,
    });
  },
};
</script>
