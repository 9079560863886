<template>
  <div class="embed" :class="`embed-${aspect}`">
    <iframe :src="src"></iframe>
  </div>
</template>

<script>
export default {
  props: {
    aspect: String,
    src: String,
  },
};
</script>

<style lang="scss">
.embed {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
  }

  & > iframe {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  $aspects: (3, 4), (16, 9);
  @each $aspect in $aspects {
    &-#{nth($aspect, 1) + '\\/' + nth($aspect, 2)} {
      &::before {
        padding-top: nth($aspect, 2) / nth($aspect, 1) * 100%;
      }
    }
  }
}
</style>
